<template>
  <div class="p-16 sm:p-24">
    <div class="flex items-center justify-between mb-8">
      <div class="flex items-center base-medium-text">
        <div
          class="w-24 h-24 bg-success flex justify-center items-center text-grey200 mr-12 rounded-full"
        >
          <fa class="text-tiny" :icon="['fas', 'check']"></fa>
        </div>
        {{
          $lang(
            'shoppingCartResources',
            subscription ? 'subscriptionItemAddedHeading' : 'itemAddedHeading'
          )
        }}
      </div>
      <div class="select-none cursor-pointer" @click="$emit('close-overlay')">
        <img
          src="~/assets/icons/close.png"
          class="h-24 w-24"
          height="24"
          width="24"
        />
      </div>
    </div>
    <div class="flex mb-16 pl-36">
      <div class="flex-1">
        <div class="small-base-text">
          {{ data.name }}
        </div>
      </div>
    </div>
    <div class="md:flex md:pl-36">
      <Button
        :is-block="true"
        :link-url="checkoutUrl"
        :size="Sizes.md"
        :theme="Themes.dark"
        :small-padding="true"
        class="mb-12 md:mr-4 md:mb-0"
        :text="$lang('shoppingCartResources', 'proceedToCheckout')"
        @click="$emit('close-overlay')"
      />
      <Button
        :is-block="true"
        :size="Sizes.md"
        :theme="Themes.ghost"
        :small-padding="true"
        class="md:ml-4"
        :text="$lang('shoppingCartResources', 'continueShopping')"
        @click="$emit('close-overlay')"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useCartStore } from '~/store/cart';
import { Sizes, Themes } from '../constants/button-layouts';
import type { ICartItem } from '~/models/api-types';

const props = defineProps<{
  data: ICartItem,
  subscription?: boolean,
}>();

const cartStore = useCartStore();
const checkoutUrl = computed(() => {
  const checkoutUrl = props.subscription
    ? cartStore.subscriptionCart.checkoutUrl || '/'
    : cartStore.cart.checkoutUrl || '/';
  return checkoutUrl;
});
</script>
<style scoped>
.text-tiny {
  font-size: 12px !important;
}
</style>
